@import 'src/assets/sass/_init.scss';
.text-gradient {
	background: $graient;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.toast-success {
	background: $success-green;
	box-shadow: 0 0 10px $medium-gray;
	color: $off-white !important;
	font-size: 14px;
	font-family: 'DIN-Regular';
	padding: 14px 16px !important;
}

.toast-error {
	background: $red-error;
	box-shadow: 0 0 10px $medium-gray;
	color: $off-white !important;
	font-size: 14px;
	font-family: 'DIN-Regular';
	padding: 14px 16px !important;
}

.toast-info {
	background: $main-color-black;
	box-shadow: 0 0 10px $medium-gray;
	color: $off-white !important;
	font-size: 14px;
	font-family: 'DIN-Regular';
	padding: 14px 16px !important;
}

.ngx-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;

	a {
		color: #6e7191 !important;
		&:hover a {
			background: transparent;
			color: #7e2eb3;
		}
	}
	li {
		background: transparent;
		position: relative;
		cursor: pointer;
		text-align: center;
		font-size: 18px;
		line-height: 22px;

		&.current {
			background: transparent;
			font-size: 18px;
			line-height: 22px;
			color: #7e2eb3;
		}

		&.small-screen {
			display: none !important;
		}
		&:hover a {
			background: transparent;
			color: #7e2eb3;
		}
	}
	.pagination-previous,
	.pagination-next {
		// height: 36px;
		// width: 36px;
		margin-top: 5px;
		.ng-star-inserted {
			display: inline-block;
			height: 36px;
			width: 36px;
			border: 1px solid #6e7191;
			border-radius: 50%;
			position: relative;
			&::after,
			&::before {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
			}
			&:hover {
				border: 1px solid #7e2eb3;
				color: #7e2eb3;
			}
		}

		// a,
		// span {
		// 	background: transparent;
		// 	overflow: hidden;
		// 	color: #6e7191 !important;
		// }

		&.disabled {
			.ng-star-inserted {
				color: #d9dbe9;
				border: 1px solid #d9dbe9;
				height: 36px;
				width: 36px;
				padding: 0;
			}
		}
		&:before,
		&::after {
			display: none !important;
		}
	}
	.pagination-next .ng-star-inserted:after {
		content: '\3e' !important;
		font-size: 14px !important;
	}
	.pagination-next .ng-star-inserted:hover {
		color: #7e2eb3 !important;
	}
	.pagination-previous .ng-star-inserted:hover {
		color: #7e2eb3 !important;
	}
	.pagination-previous .ng-star-inserted:before {
		content: '\3c' !important;
		font-size: 14px !important;
	}
}

// CHIPS STYLE

.mat-mdc-chip {
	&.mat-mdc-standard-chip {
		background-color: white !important;
		border: 1px solid #d9dbe9 !important;
		font-family: 'Poppins' !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 22px !important;
		color: #6e7191 !important;
		justify-content: center !important;

		img {
			display: block !important;
		}
		.mat-mdc-icon {
			display: none !important;
		}
		&.active {
			background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
			color: white !important;
			opacity: 1 !important;
			border-color: transparent !important;

			.mat-mdc-icon {
				display: block !important;
			}
		}

		/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
		&:not(.mat-mdc-chip-disabled):active {
			box-shadow: none !important;
		}
		&::after {
			background: transparent !important;
		}
		&:hover::after {
			opacity: 0 !important;
		}
	}
	/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
	.mat-mdc-chip-ripple {
		display: none !important;
	}
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
	.mdc-evolution-chip__text-label {
	color: inherit !important;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
	flex-basis: inherit !important;
}
// custom table
.custom-table {
	border-radius: 12px 12px 0 0;
	overflow: hidden;
	position: relative;
	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #d9dbe9;
	table {
		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 56px;
		// 	border-radius: 12px 12px 0 0;
		// 	border-width: 1px 1px 0 1px;
		// 	border-style: solid;
		// 	border-color: #d9dbe9;
		// }
	}
}
// owl slider
.owl-carousel {
	.owl-nav {
		.owl-prev,
		.owl-next {
			position: absolute;
			top: 35%;
			left: 2%;
			background: #fcfcfc !important;
			max-width: 56px;
			max-height: 56px;
			border-radius: 50%;
		}
	}
}

// autoCompleteList
.autoCompleteList {
	background: $input-background;
	transform: translate(0px, -12px) !important;
	padding-top: 1rem;
	max-height: 330px;
	overflow-y: auto;
	li {
		color: $dark-grey;
		font-size: 16px !important;
		padding-bottom: 0.8rem;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 0.8rem;
		border-bottom: 2px solid #e6e6e7;
		cursor: pointer;
		&:last-child {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
}
// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// .mat-mdc-slide-toggle-label {
// 	background-color: #fcfcfc;
// }
// .mat-mdc-calendar-body-disabled
// 	> .mat-mdc-calendar-body-cell-content:not(
// 		.mat-mdc-calendar-body-selected
// 	):not(.mat-mdc-calendar-body-comparison-identical) {
// 	color: #6e7191 !important;
// }
.custom-filter {
	.mat-mdc-form-field
		.mdc-text-field--outlined
		.mdc-notched-outline--upgraded
		.mdc-floating-label--float-above {
		background: white !important;
	}
}
// SLIDER STYLE
.mat-mdc-slider .mdc-slider__track--active_fill {
	border-color: #f8719d !important;
}
.mat-mdc-slider .mdc-slider__track--inactive {
	background-color: #eff0f6 !important;
	opacity: 1 !important;
}
.mdc-slider__thumb-knob {
	background-color: #f8719d !important;
	border-color: #f8719d !important;
}
.mat-mdc-slider .mat-ripple .mat-mdc-slider-focus-ripple {
	background-color: #f8719d !important;
	opacity: 0.24 !important;
}
.custom-filter {
	.mat-mdc-form-field
		.mdc-text-field--outlined
		.mdc-notched-outline--upgraded
		.mdc-floating-label--float-above {
		background: white !important;
	}
}

// MAT DATE PICKER
::ng-deep .mat-datepicker-toggle-default-icon {
	color: #4e4b66 !important;
}

::ng-deep .mat-datepicker-content-container {
	background-color: #eff0f6 !important;
	color: #4e4b66;
	&.mat-calendar,
	.mat-datepicker-content,
	.mat-calendar-content {
		background-color: #eff0f6 !important;
		color: #4e4b66;
		border-top: 1px solid #d9dbe9 !important;
		border-radius: 0px !important;
		padding-top: 9px !important;
	}
	&.mat-calendar-body-cell-content,
	.mat-calendar-table-header,
	.mat-calendar-body-label,
	.mat-datepicker-content .mat-calendar-previous-button,
	.mat-datepicker-content .mat-calendar-next-button,
	.mat-calendar-arrow,
	.mat-calendar-body-cell-content,
	.mat-focus-indicator {
		color: #4e4b66 !important;
	}
	.mat-calendar-arrow {
		fill: #4e4b66 !important;
	}
	.mat-calendar-table-header-divider::after,
	.mat-calendar-table-header-divider::before {
		background: #d9dbe9 !important;
	}
}
::ng-deep .mat-calendar-body-label,
::ng-deep .mat-calendar-body-cell-content {
	color: #4e4b66 !important;
}
.ok {
	color: #7e2eb3 !important;
}

::ng-deep .mat-calendar-body-selected {
	background-color: #d9dbe9 !important;
	color: #fff;
}
::ng-deep .mat-calendar-body-in-range::before {
	background: #d9dbe9 !important;
}
::ng-deep .mat-calendar-body-cell {
	&:hover {
		.mat-focus-indicator {
			background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
			color: #fcfcfc !important;
		}
	}
	&.mat-calendar-body-active {
		.mat-focus-indicator .mat-calendar-body-today {
			border: 1px solid #7e2eb3 !important;
			color: #7e2eb3 !important;
			background: transparent !important;
			&:hover {
				color: #7e2eb3 !important;
			}
		}
		.mat-calendar-body-selected {
			border: 1px solid #7e2eb3 !important;
			color: #7e2eb3 !important;
			background: transparent !important;
			&:hover {
				color: #7e2eb3 !important;
			}
		}
	}
	&.mat-calendar-body-range-start,
	&.mat-calendar-body-range-end {
		.mat-focus-indicator {
			background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
			color: #fcfcfc !important;
			box-shadow: none !important;
			border: 0 !important;
		}
	}
}

::ng-deep {
	.mat-calendar-table-header th {
		color: #1c252e !important;
	}
	.mat-datepicker-content .mat-calendar-previous-button,
	.mat-datepicker-content .mat-calendar-next-button {
		color: #1c252e !important;
	}
	.mat-calendar-spacer {
		display: none !important;
	}
	.mat-calendar-controls {
		display: flex !important;
		color: #1c252e !important;
	}
	.mat-calendar-period-button {
		color: #1c252e !important;
	}
	.mat-calendar-body-active {
		.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
				.mat-calendar-body-comparison-identical
			) {
			border: 1px solid #7e2eb3 !important;
			color: #7e2eb3 !important;
			background: transparent !important;
		}
	}
	.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
			.mat-calendar-body-comparison-identical
		) {
		border: white !important;
		color: #4e4b66 !important;
		background: transparent !important;
	}
}

@use '@angular/material' as mat;
$custom-typography: mat.define-typography-config(
	$font-family: DIN-Regular,
);
@include mat.all-component-typographies($custom-typography);
@include mat.core();

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-video';
@import 'lightgallery/scss/lg-zoom';

@import 'assets/sass/init';
@import 'assets/sass/forms';
@import 'assets/sass/custom-classes';

@layer base {
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	// custom scrollbar
	/* width */
	::-webkit-scrollbar {
		width: 8px;
		height: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
		transition: all 0.3s;
		border-radius: 5rem;
	}
}

mat-icon {
	font-family: 'Material Icons' !important;
}

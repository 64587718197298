// .mat-mdc-form-field {
// 	position: relative;
// 	margin-bottom: 12px;

// 	.mat-mdc-form-field-hint {
// 		font-size: 12px;
// 		color: $main-color-black;
// 		opacity: 0.38;
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 	.mat-mdc-form-field-outline {
// 		color: #d9dbe9 !important;
// 		height: 56px;
// 		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 		.mat-mdc-form-field-flex {
// 			padding: 3px 0.75em 0 0.75em;
// 			margin-top: 0;
// 			position: relative;
// 		}
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 	.mat-mdc-form-field-label {
// 		font-size: 16px;
// 		top: 1.7em;
// 		color: $main-color-black;
// 		opacity: 0.38;
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 	.mat-mdc-form-field-wrapper {
// 		padding: 0 !important;
// 		margin: 0px !important;
// 		height: 56px;
// 	}

// 	&.mat-mdc-form-field-invalid {
// 		margin-bottom: 30px !important;
// 		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 		.mat-mdc-form-field-outline-thick {
// 			color: $red-error !important;
// 		}
// 		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 		.mat-mdc-form-field-label {
// 			color: $main-color-black !important;
// 			opacity: 1;
// 		}
// 		/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// 		.mat-mdc-icon-button,
// 		.mat-mdc-select-arrow {
// 			z-index: 99;
// 			background: white;
// 		}

// 		// &:before {
// 		// 	content: url(../images/error.png);
// 		// 	position: absolute;
// 		// 	right: 10px;
// 		// 	top: 15px;
// 		// 	z-index: 1;
// 		// }
// 	}

// 	&.mat-mdc-focused {
// 		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 		.mat-mdc-form-field-outline {
// 			color: #f35a6b !important;
// 		}
// 		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 		.mat-mdc-form-field-label {
// 			background: $graient !important;
// 			background-clip: text !important;
// 			-webkit-text-fill-color: transparent !important;
// 			opacity: 1;
// 			transform: translateY(-1.59375em) scale(0.7);
// 		}
// 	}
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-mdc-form-field-subscript-wrapper {
// 	top: 100%;
// 	margin-top: 3px;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-mdc-form-field-subscript-wrapper {
// 	width: auto !important;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-mdc-form-field-suffix {
// 	top: 5px !important;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-mdc-form-field-label {
// 	color: $main-color-black;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// .mat-mdc-select-min-line {
// 	color: $dark-grey;
// }
.mat-mdc-select-panel {
	max-height: 200px !important;
}
// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-arrow {
	border: 0 !important;
	background-image: url(../images/icon-arrow.svg);
	width: 12px !important;
	height: 12px !important;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 0 !important;
}

// .mat-mdc-input-element {
// 	&::placeholder {
// 		color: $dark-grey !important;
// 		opacity: 0.35;
// 	}
// }

// .mat-mdc-input-element:disabled,
// .mat-mdc-form-field-disabled {
// 	input {
// 		color: #6e7191 !important ;
// 		&::placeholder {
// 			color: $medium-gray !important;
// 			opacity: 1;
// 		}
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 	.mat-mdc-form-field-label {
// 		color: #4e4b66 !important;
// 		opacity: 1 !important;
// 	}
// }

// /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
// /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
	background: #f5f5f5 !important;
	min-width: calc(100% + 20px) !important;
	transform: translate(-12px, 13px) !important;

	.mat-mdc-option {
		color: $main-color-black !important;
		font-size: 16px !important;
	}
}

// .mat-mdc-checkbox {
// 	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 	&.mat-mdc-checkbox-disabled {
// 		opacity: 0.5;
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 	.mat-mdc-checkbox-background {
// 		background: transparent;
// 		border: 1px solid $medium-gray;
// 		border-radius: 4px;
// 		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 		.mat-mdc-checkbox-checkmark {
// 			path {
// 				stroke: #fff !important;
// 			}
// 		}
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 	&.mat-mdc-checkbox-checked {
// 		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 		.mat-mdc-checkbox-background {
// 			background: $graient;
// 			border: 0;
// 		}
// 	}
// 	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
// 	.mat-mdc-checkbox-label {
// 		color: $medium-gray;
// 	}
// }

// .costom_phone {
// 	.mat-mdc-form-field-appearance-outline {
// 		margin-top: 0 !important;
// 		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// 		.mat-mdc-form-field-infix {
// 			width: auto;
// 		}
// 	}
// }

// .mat-mdc-dialog-container {
// 	background-color: white !important;
// }

// .mat-mdc-form-field-error {
// 	color: #b3261e !important;
// }

// html[dir='rtl'] {
// 	.mat-mdc-form-field-invalid {
// 		&:before {
// 			left: 9px;
// 			right: inherit;
// 		}
// 	}
// }

// // TOGGLE STYLE
// .mat-mdc-slide-toggle {
// 	height: 22px !important;
// 	overflow: hidden;
// 	/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 	/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 	/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 	.mat-mdc-slide-toggle-label {
// 		border: 1.39px solid #6e7191;
// 		border-radius: 15px;
// 		overflow: hidden;
// 		height: 22px;
// 		width: 36px;
// 		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 		.mat-mdc-slide-toggle-bar {
// 			border-radius: 15px !important;
// 			height: 11px !important;
// 			translate: 2px 2px;
// 			background: transparent;
// 			/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 			.mat-mdc-slide-toggle-thumb-container {
// 				height: 11px !important;
// 				top: -2.5px;
// 			}
// 			/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 			.mat-mdc-slide-toggle-thumb {
// 				background-color: #4e4b66;
// 				height: 11px !important;
// 				width: 11px !important;
// 				// margin-top: 2px;
// 			}
// 		}
// 	}
// 	&.mat-mdc-checked {
// 		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 		/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 		.mat-mdc-slide-toggle-label {
// 			border-color: transparent;
// 			background-image: linear-gradient(90deg, #f2546e 0%, #fa825c 100%);
// 			/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 			/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 			.mat-mdc-slide-toggle-bar {
// 				height: 15px !important;
// 				/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 				.mat-mdc-slide-toggle-thumb-container {
// 					height: 16px !important;
// 					top: -3px;
// 					transform: translate3d(14px, 0, 0);
// 				}
// 				/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// 				.mat-mdc-slide-toggle-thumb {
// 					background-color: #fff;
// 					height: 17px !important;
// 					width: 17px !important;
// 				}
// 			}
// 		}
// 	}
// }

// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// [dir='rtl'] .mat-mdc-slide-toggle-bar {
// 	margin-left: 0 !important;
// 	margin-right: 23px !important;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
// [dir='rtl']
// 	.mat-mdc-slide-toggle.mat-mdc-checked
// 	.mat-mdc-slide-toggle-thumb-container {
// 	transform: translate3d(19px, 0, 0) !important;
// }

// // RADIO BUTTON STYLE
// /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// .mat-mdc-radio-container {
// 	margin-left: 5px;
// 	margin-right: 5px;
// }
// /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// .mat-mdc-radio-outer-circle {
// 	border-color: $medium-gray !important;
// }
// .mat-mdc-radio-button {
// 	margin-left: 5px;
// 	margin-right: 5px;
// 	/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// 	.mat-mdc-radio-ripple {
// 		display: none;
// 	}
// }
// /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// .mat-mdc-radio-label-content {
// 	padding: 0 !important;
// }

// .mat-mdc-accent {
// 	/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// 	.mat-mdc-radio-inner-circle {
// 		background-image: $graient !important;
// 	}
// }

// /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// .mat-mdc-accent.mat-mdc-radio-checked {
// 	/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
// 	.mat-mdc-radio-outer-circle {
// 		border-color: #f35a6b !important;
// 	}
// }

// // ANGULAR TIME PICKER
.timepicker__header,
.clock-face__clock-hand,
.clock-face__clock-hand_minute {
	background: $graient !important;
}
.clock-face__number > span.active {
	background: $graient !important;
}
.timepicker-button {
	color: #7e2eb3 !important;
}

// after update
.mat-mdc-form-field {
	border: none !important;
}
.mat-mdc-select-placeholder {
	color: #4e4b66 !important;
	margin: 6px;
}
.mat-mdc-select-value-text {
	color: #4e4b66 !important;
	margin: 0 5px !important;
}
.mat-mdc-form-field:not(.mat-form-field-disabled)
	.mat-mdc-floating-label.mdc-floating-label {
	color: $dark-grey !important;
}
.mdc-floating-label--required:not(
		.mdc-floating-label--hide-required-marker
	)::after {
	color: #7e2eb3 !important;
}
//mat-pseudo-checkbox
.mat-pseudo-checkbox-full {
	border-color: #d9dbe9 !important;
	&.mat-pseudo-checkbox-checked {
		border-color: #a064c1 !important;
		background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
	}
	&.mat-pseudo-checkbox-checked::after {
		color: white !important;
	}
}
.mdc-list-item__primary-text {
	color: $dark-grey !important;
}
//mat-pseudo-checkbox
.mdc-checkbox
	.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
		[data-indeterminate='true']
	)
	~ .mdc-checkbox__background {
	border-color: #d9dbe9 !important;
	background-color: transparent;
}
.mdc-checkbox
	.mdc-checkbox__native-control:enabled:checked
	~ .mdc-checkbox__background,
.mdc-checkbox
	.mdc-checkbox__native-control:enabled:indeterminate
	~ .mdc-checkbox__background,
.mdc-checkbox
	.mdc-checkbox__native-control[data-indeterminate='true']:enabled
	~ .mdc-checkbox__background {
	border-color: #a064c1 !important;
	background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
}
.mdc-checkbox
	.mdc-checkbox__native-control:enabled
	~ .mdc-checkbox__background
	.mdc-checkbox__checkmark {
	color: #fcfcfc !important;
}
.mdc-checkbox
	.mdc-checkbox__native-control[disabled]:checked
	~ .mdc-checkbox__background,
.mdc-checkbox
	.mdc-checkbox__native-control[disabled]:indeterminate
	~ .mdc-checkbox__background,
.mdc-checkbox
	.mdc-checkbox__native-control[data-indeterminate='true'][disabled]
	~ .mdc-checkbox__background {
	// border-color: #d9dbe9 !important;
	// border-color: #d9dbe9 !important;
	background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
}
.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
	display: none !important;
}
.mdc-checkbox--disabled {
	opacity: 0.5;
}
.mdc-switch__ripple {
	display: none !important;
}
.mdc-switch__handle {
	width: 13px !important;
	height: 13px !important;
	left: 4px !important;
	right: 4px !important;
	background-color: #6e7191 !important;
}
.mdc-switch:disabled {
	&.mdc-switch--checked {
		background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
		border-radius: 13px !important;
		.mdc-switch__shadow {
			background: #fcfcfc;
		}
	}

	&.mdc-switch--unselected {
		border-radius: 15px !important;
		border: 1px solid #6e7191 !important;
		background-color: transparent;
		opacity: 0.5 !important;
		.mdc-switch__shadow {
			background: #fcfcfc !important;
			background-color: #6e7191 !important;
		}
	}
}
.mat-mdc-form-field-subscript-wrapper {
	margin-bottom: 7px;
}
.mat-mdc-form-field-bottom-align::before {
	display: none !important;
}

.mat-mdc-form-field-bottom-align::after {
	display: block !important;
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
	position: relative !important;
}
.mdc-switch:enabled .mdc-switch__track::before {
	border: 1px solid #6e7191 !important;
	background-color: transparent !important;
}
.mdc-switch .mdc-switch__track {
	height: 22px !important;
	width: 36px !important;
	border-radius: 15px !important;
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active)
	.mdc-switch__handle::after {
	background: #6e7191 !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
	background: #fcfcfc !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
	background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
}

.mat-mdc-form-field .mdc-notched-outline__notch {
	border-right: none !important;
	border-left: none !important;
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic
	.mdc-evolution-chip__graphic {
	display: none !important;
}
.mat-mdc-text-field-wrapper {
	padding: 0 !important;
	margin: 0px !important;
	height: 56px;
	border: 1px solid #d9dbe9 !important;
	border-radius: 4px !important;

	&.mdc-text-field--focused {
		border: none !important;
	}
}
.custom-mob {
	.mat-form-field-invalid {
		.mat-mdc-text-field-wrapper {
			border: 1px solid #d9dbe9 !important;
		}
	}
}
.mat-form-field-invalid {
	.mat-mdc-text-field-wrapper {
		border: none !important;
	}
	&.mdc-text-field--invalid {
		border: none !important;
	}
	&.mdc-text-field--focused {
		border: none !important;
	}
}
.custom-form-field-validation {
	.mat-form-field-invalid {
		.mat-mdc-text-field-wrapper {
			border: red !important;
		}
		&.mdc-text-field--invalid {
			border: red !important;
		}
		&.mdc-text-field--focused {
			border: red !important;
		}
	}
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
	.mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
	.mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
	.mdc-notched-outline__trailing,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
	.mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
	.mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
	.mdc-notched-outline__trailing {
	border-top: 1px solid #7e2eb3 !important;
	border-bottom: 1px solid #7e2eb3 !important;
}

html[dir='ltr'] {
	.mdc-text-field--outlined.mdc-text-field--invalid:not(
			.mdc-text-field--disabled
		)
		.mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(
			.mdc-text-field--disabled
		).mdc-text-field--focused
		.mdc-notched-outline__leading {
		border-left: 1px solid #7e2eb3 !important;
	}
	.mdc-text-field--outlined.mdc-text-field--invalid:not(
			.mdc-text-field--disabled
		)
		.mdc-notched-outline__trailing,
	.mdc-text-field--outlined:not(
			.mdc-text-field--disabled
		).mdc-text-field--focused
		.mdc-notched-outline__trailing {
		border-right: 1px solid #7e2eb3 !important;
	}
}

html[dir='rtl'] {
	.mdc-text-field--outlined.mdc-text-field--invalid:not(
			.mdc-text-field--disabled
		)
		.mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(
			.mdc-text-field--disabled
		).mdc-text-field--focused
		.mdc-notched-outline__leading {
		border-right: 1px solid #7e2eb3 !important;
	}
	.mdc-text-field--outlined.mdc-text-field--invalid:not(
			.mdc-text-field--disabled
		)
		.mdc-notched-outline__trailing,
	.mdc-text-field--outlined:not(
			.mdc-text-field--disabled
		).mdc-text-field--focused
		.mdc-notched-outline__trailing {
		border-left: 1px solid #7e2eb3 !important;
	}
}

::ng-deep .mat-mdc-placeholder-required {
	color: #7e2eb3 !important;
	opacity: 1 !important;
}

.mdc-text-field__input::placeholder {
	color: #4e4b66 !important;
}
.mat-mdc-form-field
	.mdc-text-field--outlined
	.mdc-notched-outline--upgraded
	.mdc-floating-label--float-above {
	background: #fcfcfc !important;
}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
	padding: 0 12px !important;
	color: $medium-gray !important;
}
.mat-mdc-radio-button
	.mdc-radio
	.mdc-radio__native-control:enabled:checked
	+ .mdc-radio__background {
	.mdc-radio__outer-circle {
		border-color: #7e2eb3 !important;
	}
	.mdc-radio__inner-circle {
		border-color: #7e2eb3 !important;
	}
}
.mat-mdc-radio-button {
	--mdc-form-field-label-text-color: black !important;
}
.mdc-form-field > label {
	color: $main-color-black !important ;
}
.mat-mdc-radio-button.mat-accent {
	--mat-radio-checked-ripple-color: transparent !important;
}
.mat-mdc-radio-button
	.mdc-radio
	.mdc-radio__native-control:enabled:not(:checked)
	+ .mdc-radio__background
	.mdc-radio__outer-circle {
	border-color: #6e7191 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	background-color: white !important;
}
.mat-mdc-button {
	height: 42px;
	&.cancelRedBtn {
		border: 1px solid #d9dbe9 !important;
		color: #b3261e !important;
		padding: 20px 0;
	}
	&.cancelBtn {
		border: 1px solid #d9dbe9 !important;
		color: #4e4b66 !important;
		padding: 20px 0;
	}

	&.mat-mdc-button-disabled.mat-mdc-button-disabled {
		background: $light-grey !important;
		color: rgba($color: $dark-grey, $alpha: 0.38) !important;
	}
}
.mat-mdc-button.cancelRedBtn:hover {
	background: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%) !important;
	color: #fcfcfc !important;
	border: none !important;
}
.mat-mdc-button[disabled] {
	background: $light-grey !important;
	color: rgba($color: $dark-grey, $alpha: 0.38) !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
	background-color: #6e7191 !important;
}

.mdc-switch__icons {
	display: none !important;
}
// TEXT AREA

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: #fcfcfc !important;
	border: 1px solid #d9dbe9 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
	border: none !important;
}
// TEXT AREA
.custom-filter {
	.mat-mdc-icon-button img {
		height: 9px !important;
	}
}

@font-face {
	font-family: 'Conv_Poppins-Regular';
	src: url('../fonts/Poppins-Regular.eot');
	src: local('☺'), url('../fonts/Poppins-Regular.woff') format('woff'),
		url('../fonts/Poppins-Regular.ttf') format('truetype'),
		url('../fonts/Poppins-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Poppins-Medium';
	src: url('../fonts/Poppins-Medium.eot');
	src: local('☺'), url('../fonts/Poppins-Medium.woff') format('woff'),
		url('../fonts/Poppins-Medium.ttf') format('truetype'),
		url('../fonts/Poppins-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Poppins-SemiBold';
	src: url('../fonts/Poppins-SemiBold.eot');
	src: local('☺'), url('../fonts/Poppins-SemiBold.woff') format('woff'),
		url('../fonts/Poppins-SemiBold.ttf') format('truetype'),
		url('../fonts/Poppins-SemiBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Poppins-Bold';
	src: url('../fonts/Poppins-Bold.eot');
	src: local('☺'), url('../fonts/Poppins-Bold.woff') format('woff'),
		url('../fonts/Poppins-Bold.ttf') format('truetype'),
		url('../fonts/Poppins-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF Arabic';
	src: url('../fonts/SFArabic-Regular.woff2') format('woff2'),
		url('../fonts/SFArabic-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'DIN-Regular';
	src: url('../fonts/DIN-Regular.otf') format('opentype');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-Medium';
	src: url('../fonts/DIN-Medium.otf') format('opentype');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-Bold';
	src: url('../fonts/DIN-Bold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

$graient: linear-gradient(90deg, #a064c1 0%, #7e2eb3 100%);
$main-color-black: #1c252e;
$medium-gray: #6e7191;
$secondry-color-Gray: #d9dbe9;
$dark-grey: #4e4b66;
$light-grey: #f5f5f5;
$grey-background: #f6f5f5;
$input-background: #eff0f6;
$off-white: #fcfcfc;
$red-error: #b3261e;
$success-green: #00ba88;
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-size: 14px !important;
	color: #1c252e;
}

body {
	overflow-x: hidden;
}
